import dayjs, { Dayjs } from "dayjs";
import { Organization, User } from "db";
import {
  AnalysisStatus,
  FeedbackAnalysisItem,
  FeedbackSource,
  FeedbackSourceAnalysisStatusInfo,
  FeedbackSourceExtra,
  FeedbackSourceType,
} from "integrations/klassy/feedback-api-v2-schema";

import { DateRange } from "app/core/utils/dates";

import { TrackedAppWithName } from "app/tracked-apps/utils/types";

export { FeedbackSourceType } from "integrations/klassy/feedback-api-v2-schema";

export type FeedbackTerm = {
  term: string;
  id: string;
  mentions: number;
  reviewsCount: number;
  type: string;
  issueId?: string;
  issueKey?: string;
  issueUrl?: string;
};

export type FeedbackFilterDateOption = Date | "week" | "month";
export type FeedbackFilterDayjsOption = Dayjs | DateRange | "week" | "month";

export function isFeedbackFilterDayjsDateRangeOption(
  selection: FeedbackFilterDayjsOption
): selection is DateRange {
  if (dayjs.isDayjs(selection) || selection === "week" || selection === "month") return false;
  if (selection.dateStart && selection.dateEnd) return true;

  return false;
}

export type UserWithOrganization = User & {
  organizationId: Organization["id"];
  organization: Organization;
};

// Feedback sources
export type FeedbackSourceApp = {
  type: "app";
  data: TrackedAppWithName;
  createdAt: Date;
};

export type SourceInfo<E extends FeedbackSourceExtra = FeedbackSourceExtra> = Omit<
  FeedbackSource<E>,
  "type" | "createdAt"
>;

export type FeedbackSourceData<E extends FeedbackSourceExtra = FeedbackSourceExtra> = {
  type: FeedbackSourceType;
  data: SourceInfo<E>;
  createdAt: Date;
};

export type FeedbackComment = {
  body: string;
  date: string;
  author: string;
  feedbackThread: FeedbackThread;
};

export type FeedbackThread = {
  extra: FeedbackThreadExtraApp;
};

export type FeedbackThreadExtraApp = {
  author: string;
  rating: number;
  timestamp: string;
};

export type FeedbackCommentsData = {
  comments: Array<FeedbackComment>;
  total: number;
};

export enum FeedbackSourceNames {
  zendesk = "Zendesk",
  front = "Front",
  intercom = "Intercom",
  jira = "Jira",
  "free-form-text" = "Free-form text",
  g2 = "G2",
  freshdesk = "Freshdesk",
  hubspot = "Hubspot",
  github = "Github",
  stackOverflow = "Stack Overflow",
  twitter = "Twitter",
  salesforce = "Salesforce",
  dixa = "Dixa",
  hackerNews = "Hacker News",
  gongCalls = "Gong Calls",
  csv = "CSV",
  kustomer = "Kustomer",
  zapier = "Zapier",
  group = "Group",
  delighted = "Delighted",
  dovetail = "Dovetail",
  discord = "Discord",
  fireflies = "Fireflies",
  fathom = "Fathom",
  zapier_intercom = "Intercom",
  zapier_zendesk = "Zendesk",
  helpscout = "Help Scout",
  slack = "Slack",
  zoom = "Zoom",
  "googlemeet" = "Google Meet",
  canny = "Canny",
  excel = "Excel",
  googlesheet = "Google Sheet",
  crisp = "Crisp",
  notion = "Notion",
  clickup = "ClickUp",
  reddit = "Reddit",
  hotjar = "Hotjar",
  surveymonkey = "SurveyMonkey",
  typeform = "Typeform",
}

export type FeedbackSources<E extends FeedbackSourceExtra = FeedbackSourceExtra> =
  FeedbackSourceData<E>;

export type FeedbackReviewSummaryData = {
  reviews: {
    complaints: Array<FeedbackAnalysisItem>;
    requests: Array<FeedbackAnalysisItem>;
    loved: Array<FeedbackAnalysisItem>;
    competitors: Array<FeedbackAnalysisItem>;
    summary: string;
    numberOfThreads: number;
    numberOfAnalysedReviews: number;
  };
};

export type FeedbackSummaryApp = {
  type: "app";
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  trackedApp: TrackedAppWithName | null;
};

export type FeedbackSummaryAppNew = {
  type: FeedbackSourceType.appNew;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryAllSources = {
  type: FeedbackSourceType.all;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryGroupSources = {
  type: FeedbackSourceType.group;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type ZendeskInfo = {
  // TODO: Update with klassy info
  id: number;
};

export type FeedbackSummaryZendesk = {
  type: FeedbackSourceType.zendesk;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
  zendesk: ZendeskInfo;
};

export type FeedbackSummaryFront = {
  type: FeedbackSourceType.front;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryTwitter = {
  type: FeedbackSourceType.twitter;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryIntercom = {
  type: FeedbackSourceType.intercom;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryJira = {
  type: FeedbackSourceType.jira;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryG2 = {
  type: FeedbackSourceType.g2;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryTrustpilot = {
  type: FeedbackSourceType.trustpilot;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryDixa = {
  type: FeedbackSourceType.dixa;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryHackerNews = {
  type: FeedbackSourceType.hackerNews;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryGongCalls = {
  type: FeedbackSourceType.gongCalls;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryFreeFormText = {
  type: FeedbackSourceType.freeFormText;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryCsv = {
  type: FeedbackSourceType.csv;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryFreshdesk = {
  type: FeedbackSourceType.freshdesk;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryHubspot = {
  type: FeedbackSourceType.hubspot;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryGithub = {
  type: FeedbackSourceType.github;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryKustomer = {
  type: FeedbackSourceType.kustomer;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryStackOverflow = {
  type: FeedbackSourceType.stackOverflow;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummarySalesForce = {
  type: FeedbackSourceType.salesforce;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaryZapier = {
  type: FeedbackSourceType.zapier;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummarySurvey = {
  type: FeedbackSourceType.survey;
  summary: FeedbackReviewSummaryData;
  status: AnalysisStatus;
  analysisStatus: FeedbackSourceAnalysisStatusInfo;
};

export type FeedbackSummaries =
  | FeedbackSummaryApp
  | FeedbackSummaryAppNew
  | FeedbackSummaryAllSources
  | FeedbackSummaryZendesk
  | FeedbackSummaryFront
  | FeedbackSummaryIntercom
  | FeedbackSummaryFreeFormText
  | FeedbackSummaryCsv
  | FeedbackSummaryG2
  | FeedbackSummaryTrustpilot
  | FeedbackSummaryFreshdesk
  | FeedbackSummaryHubspot
  | FeedbackSummaryGithub
  | FeedbackSummaryKustomer
  | FeedbackSummaryJira
  | FeedbackSummaryStackOverflow
  | FeedbackSummaryTwitter
  | FeedbackSummarySalesForce
  | FeedbackSummaryDixa
  | FeedbackSummaryHackerNews
  | FeedbackSummaryGongCalls
  | FeedbackSummaryZapier
  | FeedbackSummaryGroupSources
  | FeedbackSummarySurvey;

export enum ChatTranscriptService {
  zoom = "Zoom",
  gong = "Gong",
  fathom = "Fathom",
  google = "Google Hangouts",
  fireflies = "Fireflies",
}

export enum AnalysisType {
  regular = "regular",
  secondary = "secondary",
  swot = "swot",
  empathy = "empathy",
  what = "what",
}
